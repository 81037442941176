import { useState } from "react";
import React, { createContext, useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { productItemHome } from "../../pages/home";
import { productData } from "../../pages/products/mockData";
import { store } from "../store";
import Banner from "./banner/Banner";
import BestSeller from "./best-seller/BestSeller";
import Brands from "./brands/Brands";
import CategoriesItems from "./categories-items/CategoriesItems";
import CostInfo from "./cost-info/CostInfo";
import Network from "./network/Network";
import ProductsCategory from "./products-category/ProductsCategory";
import Products from "./products/Products";
import ServiceList from "./service-list/ServiceList";
import TodayDeals from "./today-deals/TodayDeals";
import TrendingProducts from "./trending-products/TrendingProducts";
import AddingShow from "./network/AddingShow";
import PropertiesForRent from "../../pages/featureProducts";
import FeatureAgents from "../featureAgent/FeatureAgents";
import Testimonial from "../testimonial/Testimonial";
function Home() {
  const productData = useContext(productItemHome)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Banner />
      {/* <ServiceList /> */}
      <ProductsCategory />
      <Products />
      <PropertiesForRent />
      <TrendingProducts />
      <FeatureAgents />
      <TodayDeals />
      <Testimonial />
      {/*<section className="sellerCategorySec">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <CategoriesItems />
            </div>
          </div>
        </div>
      </section>
      <Brands />
      <AddingShow />
      <Network /> */}
      {/* <CostInfo /> */}

    </>
  );
}

export default Home;
