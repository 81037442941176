import React, { createContext, useEffect } from 'react';
import Home from '../../components/home/Home';
import { productData } from '../products/mockData';
import ProductItem from '../../components/home/products/product/ProductItem';
export const productItemHome = createContext();
function HomePage({ setShow }) {

  return (
    <>
      <productItemHome.Provider value={ProductItem}>
        <Home />
      </productItemHome.Provider>

    </>
  )
}

export default HomePage