import React from "react";
import { useGetCategoriesQuery } from "../../products/productSlice";

import "./ProductsCategory.css";
import { productData } from "../../../pages/product-detail/mockData";
import { HiLocationMarker } from "react-icons/hi";

function ProductsCategory() {
  const { data, isLoading, error } = useGetCategoriesQuery();

  return (
    <>
      <section className="productsCategorySec">
        <div className="container">


          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fisherman-content mb-3">
                <h3>Featured &nbsp; Projects</h3>
                <p>We make the best choices with the hottest and most  projects. Whether you buy or sell property in India, please visit the details below to find out more.</p>
              </div>
            </div>

            {isLoading && <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}
            {/* {error && <h4 style={{ textAlign: "center", color: "red" }}>Server Error</h4>} */}
            <div className="row">
              {/* {data?.slice(0, 6).map((item, i) => { */}
              {productData.data?.slice(0, 3).map((item, i) => {

                return <div key={item._id} className="col-lg-4 col-md-6 col-sm-12 mb-5">
                  <div className="productsCategoryItem">
                    <div className="categoryBody">
                      <div className="singleProducts">
                        <a href="/products">
                          <img src={item.bigUrl} alt="Product" />
                        </a>
                        <span className="apartText">
                          Apartment
                        </span>
                      </div>
                      <p>
                        Each apartment has been individually designed to maximise space
                      </p>
                      <div className="">
                        <span className="" style={{ fontSize: "12px" }}>Price : from <strong> 400 millions</strong></span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                        <span><b> <HiLocationMarker /></b><span style={{ fontSize: "12px" }}>Dubai,Umm al-Qaiwan</span></span>
                      </div>
                    </div>
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsCategory;

