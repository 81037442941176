import productSmall1 from "../../assets/img/products/productSmall1.jpg";
import productSmall2 from "../../assets/img/products/productSmall2.jpg";
import productSmall3 from "../../assets/img/products/productSmall3.jpg";
// import productSmall4 from "../../assets/img/products/productSmall4.jpg";


import productLarge1 from "../../assets/img/featureproducts/f1.jpg";
import productLarge2 from "../../assets/img/featureproducts/f2.jpg";
import productLarge3 from "../../assets/img/featureproducts/f3.jpg";
import productLarge4 from "../../assets/img/featureproducts/f4.jpg";


export const productData = {
  data: [
    {
      id: 1,
      url: productSmall1,
      bigUrl: productLarge1,
      alt: "Fertilizer 1",
    },
    {
      id: 2,
      url: productSmall2,
      bigUrl: productLarge2,
      alt: "Fertilizer 2",
    },
    {
      id: 3,
      url: productSmall3,
      bigUrl: productLarge3,
      alt: "Fertilizer 3",
    },
    // {
    //   id: 4,
    //   url: productSmall3,
    //   bigUrl: productLarge4,
    //   alt: "Fertilizer 4",
    // },
  ]
};
