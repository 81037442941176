import React, { useState } from "react";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";

// You need to import the CSS only once
// import "react-awesome-lightbox/build/style.css";

import "../../../home/products/product/ProductItem.css";
import "./featurePro.css"
import {
  setFeachers, useGetProductDetailQuery,
} from "../../../products/productSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ImgesData } from "../../proImgs/ImgesData";
import { AiTwotoneStar } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { rentPropertiesData } from "../../../../pages/featureProducts/mockData";
import { BiSolidBath } from "react-icons/bi";
import { FaBed } from "react-icons/fa";
import { RxRulerHorizontal } from "react-icons/rx";

function FeaturedProduct({ isLoading, data, error, handleShow }) {
  const curr = window.localStorage.getItem('currencySym')
  const currencySymbol = curr ? curr : 'ZK'
  const dispacher = useDispatch()

  useEffect(() => {
    dispacher(setFeachers(data))
  }, [data])

  return (
    <>
      {isLoading && (
        <div className="loaderIcon">
          <BiLoaderAlt />
        </div>
      )}
      <div className="container">
        <div className="row">
          {rentPropertiesData?.data?.slice(0, 8).map((item, i) => {

            {/* <div className="col-lg-3 col-md-6 col-sm-12" key={item._id}>
            <div className="featuredInfo">
              <div className="featuredFigure">
                <div className="featuredImg" style={{ textAlign: "center" }}>
                  <Link to={`/product/${item._id}`}>
                    <img src={item?.url} alt="Product" className="imgProduct" />
                  </Link>
                  <div className="quickView">
                    <ul>
                      <li className="viewProduct">
                        <button
                          className="quick_view_btn"
                          onClick={(e) => {
                            handleShow(item._id)
                          }}
                        >
                          <FiSearch />
                        </button>
                      </li>
                      <li className="addProduct">
                        <Link to="#">
                          <GrAdd />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="hotList">
                  <li>
                    <Link to="/products">hot</Link>
                  </li>
                  <li>
                    <Link to="products">-20%</Link>
                  </li>
                </ul>
              </div>
              <div className="featuredContent">
                <h6>category</h6>
                <h5>
                  <Link to={`/product/${item._id}`}>{item.name}</Link>
                </h5>
                <Rating />
                <div className="rateDigit">
                  <span className="cross">{currencySymbol} 59.00</span>
                  <span className="currentPrice">{currencySymbol} {item?.variations[0]?.sale_rate}</span>
                </div>
                <div className="buyNowInfo">


                  <Link className="btn btn-danger addCart" to={`/product/${item._id}`}
                  >
                    View Detail
                  </Link>


                  <Link to={`/product/${item._id}`} className="btn btn-primary buyNow">
                    <BsFillCartFill /> Buy Now
                  </Link>
                </div>
                <div className="productDesc">
                  <p>{item.meta_description}</p>
                </div>
                <div className="featuredOption">
                  <select defaultValue={"DEFAULT"}>
                    <option value="DEFAULT">Select Option</option>
                    <option value="one">One</option>
                    <option value="two">Two</option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
            return < div key={""} className="col-lg-3 col-md-6 col-sm-12 mb-2" >
              <div className="spBorder">
                <div className="">
                  <div className="categoryBody">
                    <div className="singleProducts m-0">
                      <a href="/products" className="protiesfSale" style={{ backgroundImage: `url(${item?.url})` }}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="star">
                            <AiTwotoneStar />
                            <AiTwotoneStar />
                            <AiTwotoneStar />
                          </div>
                          <div className="sales">
                            FOR RENT
                          </div>
                        </div>

                      </a>
                      <div className="d-flex align-items-center justify-content-between my-2">
                        <div class="heartWish"><BsFillSuitHeartFill /></div>
                        <div className="">
                          <span className=""><b><GoLocation /></b></span>
                          <p className="d-inline">Dubai</p>
                        </div>

                      </div>

                    </div>
                    <div className="seprate">
                      <span className=""><FaBed /></span>&nbsp;<span className="d-inline vari round">3</span>
                      &nbsp;
                      <span className=""><BiSolidBath /></span>&nbsp;<span className="d-inline vari round">3</span>
                      &nbsp;
                      <span className="rentSize"><RxRulerHorizontal /></span>&nbsp;<span className="d-inline vari">900 &nbsp;ft<sup>2</sup></span>
                    </div></div>
                </div>
              </div>
            </div >

          })}
        </div>
      </div>{
        error && (
          <div className="alertMsg mb-4" role="alert">
            No Data Found
          </div>
        )
      }

    </>
  );
}

export default FeaturedProduct;
