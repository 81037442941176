import React from 'react'
import Fancybox from './PropertiesGallery'

function Gallery() {
    return (

        <Fancybox>

            <div className='container'>
                <h6 className='text-center bg-danger text-white rounded'>Gallery</h6>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                        <a data-fancybox="gallery" href="https://storage.googleapis.com/theme-vessel-items/checking-sites/nest-2-html/HTML/main/img/properties/properties-6.jpg">
                            <img alt="" src="https://storage.googleapis.com/theme-vessel-items/checking-sites/nest-2-html/HTML/main/img/properties/properties-6.jpg" />
                        </a>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                        <a data-fancybox="gallery" href="https://storage.googleapis.com/theme-vessel-items/checking-sites/nest-2-html/HTML/main/img/recently-properties/img-1.jpg">
                            <img alt="" src="https://storage.googleapis.com/theme-vessel-items/checking-sites/nest-2-html/HTML/main/img/recently-properties/img-1.jpg" />
                        </a>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                        <a data-fancybox="gallery" href="https://storage.googleapis.com/theme-vessel-items/checking-sites/nest-2-html/HTML/main/img/properties/properties-1.jpg">
                            <img alt="" src="https://storage.googleapis.com/theme-vessel-items/checking-sites/nest-2-html/HTML/main/img/properties/properties-1.jpg" />
                        </a>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-6'>
                        <a data-fancybox="gallery" href="https://storage.googleapis.com/theme-vessel-items/checking-sites/nest-2-html/HTML/main/img/properties/properties-5.jpg">
                            <img alt="" src="https://storage.googleapis.com/theme-vessel-items/checking-sites/nest-2-html/HTML/main/img/properties/properties-5.jpg" />
                        </a>
                    </div>

                </div>
            </div>

            {/* <p>
                <button data-fancybox="dialog" data-src="#dialog-content">Launch Dialog</button>
            </p>
            <div id="dialog-content" style={{ display: "none", maxWidth: "500px" }}>
                <h2>Hello, world!</h2>
                <p>
                    <input type="text" value="" />
                </p>
                <p>
                    Try hitting the tab key and notice how the focus stays within the dialog itself.
                </p>
                <p>
                    To close dialog hit the esc button, click on the overlay or just click the close button.
                </p>
                <p className="margin-bottom--none">
                    Element used to launch the modal would receive focus back after closing.
                </p>
            </div> */}
        </Fancybox>
    )
}

export default Gallery
