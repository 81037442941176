import pfs1 from "../../assets/img/pfRent/pfr1.jpg";
import pfs2 from "../../assets/img/pfRent/pfr2.jpg";
import pfs3 from "../../assets/img/pfRent/pfr3.jpg";
import pfs4 from "../../assets/img/pfRent/pfr4.jpg";
import pfs5 from "../../assets/img/pfRent/pfr5.jpg";
import pfs6 from "../../assets/img/pfRent/pfr6.jpg";
import pfs7 from "../../assets/img/pfRent/pfr7.jpg";
import pfs8 from "../../assets/img/pfRent/pfr8.jpg";


export const rentPropertiesData = {
    data: [
        {
            id: 1,
            url: pfs1,
            alt: "productSale1",
        },

        {
            id: 3,
            url: pfs3,
            alt: "productSale3",
        },
        {
            id: 4,
            url: pfs4,
            alt: "productSale4",
        },
        {
            id: 5,
            url: pfs5,
            alt: "productSale5",
        },
        {
            id: 6,
            url: pfs6,
            alt: "productSale6",
        },
        {
            id: 7,
            url: pfs7,
            alt: "productSale7",
        },
        {
            id: 2,
            url: pfs2,
            alt: "productSale2",
        },
        {
            id: 8,
            url: pfs8,
            alt: "productSale8",
        },
    ]
};
