import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter, BsInstagram, BsWhatsapp, BsFillEnvelopeFill } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { MdCall, MdShareLocation } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import "./Footer.css";
import axios from "axios";
import logoF from "../../assets/img/logo.png"
function Footer() {

  const [state, setState] = useState({
    about_company: "",
    office_address: "",
    location: "",
    phoneNo: "",
    email: "",
  })


  const getData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/adminWeb_footer/get`)
    setState({
      about_company: res.data.about_company,
      office_address: res.data.office_address,
      location: res.data.contact_info.location,
      phoneNo: res.data.contact_info.phoneNo,
      email: res.data.contact_info.email,
    })
    window.localStorage.setItem('callNum', res.data.contact_info.phoneNo)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {/* Start Footer Section */}
      <section className="footer-section footer-bg">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="aboutCompanyText">
                <h3>About Company</h3>
                <p>
                  {state?.about_company}
                </p>
              </div>
            </div> */}
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <img src={logoF} alt="logo" width={100} />
                </div>

                <p>
                  <span><MdShareLocation /></span>
                  {/* {state?.office_address} */}
                  <span>
                    Address
                  </span>
                  <br />
                  <span>
                    <BsFillEnvelopeFill /> Email: xyz@gmail.com
                  </span>
                </p>
                <ul className="footer-social">
                  <li>
                    <a href="https://mmslfashions.in/" className="facebook">
                      <FaFacebookF />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="twitter">
                      <BsTwitter />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="instagram">
                      <BsInstagram />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="whatsapp">
                      <BsWhatsapp />
                    </a>
                  </li>


                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>ABOUT</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                    <Link to="/about"> About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact"> Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/blog"> Careers</Link>
                  </li>
                  <li>
                    <Link to="/photo-gallery"> Terms and Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>MORE INFORMATION</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                    <Link to="/about"> All projects</Link>
                  </li>
                  <li>
                    <Link to="/contact"> All Properties</Link>
                  </li>
                  <li>
                    <Link to="/blog"> Houses For Sale</Link>
                  </li>
                  <li>
                    <Link to="/photo-gallery"> Houses For Rent</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>News</h3>
                </div>
                <ul className="footer-quick-links">
                  <li>
                    <Link to="/about"> Latest news</Link>
                  </li>
                  <li>
                    <Link to="/contact">House architecture</Link>
                  </li>
                  <li>
                    <Link to="/blog"> House design</Link>
                  </li>
                  <li>
                    <Link to="/photo-gallery"> Building materials</Link>
                  </li>
                </ul>

                {/* <form className="newsletter-form">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your email"
                    name="EMAIL"
                    required
                    autoComplete="off"
                  />
                  <button type="submit">
                    Subscribe Now
                    <i className="flaticon-plus"></i>
                  </button>
                  <div id="validator-newsletter" className="form-result"></div>
                </form> */}
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="copyright-area-content">
              <div className="copyright-left">
                <p>
                  Copyright @
                  <script
                    data-cfasync="false"
                    src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
                  ></script>
                  <script>document.write(new Date().getFullYear())</script>{" "}
                  All Rights Reserved . Powered By Abaris Softech
                </p>
              </div>

              <div className="copyright-right">
                <ul>
                  <li>
                    <Link to="/terms-of-use">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Footer Section */}
    </>
  );
}

export default Footer;
