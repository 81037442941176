import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FeatureAgents.css"
import { BiSolidLike } from "react-icons/bi";
import { GrStar } from "react-icons/gr";
import { LiaStarHalfAltSolid } from "react-icons/lia";
var testiomnialData = [

    {
        avatar: "https://img.freepik.com/free-photo/close-up-portrait-young-bearded-man-white-shirt-jacket-posing-camera-with-broad-smile-isolated-gray_171337-629.jpg",
        name: "Mr Adnaan",
        review: "Unbelievable gem! Altered my life. A must-have now. Wholeheartedly advise it to everyone. An absolute game-changer.One of those rare places where the photos do not do justice to the reality – ."
    },
    {
        avatar: "https://img.freepik.com/free-photo/handsome-african-guy-with-stylish-haircut-taking-photo-digital-camera_171337-1345.jpg",
        name: "Mr Bin Al Makdum Bilal",
        review: "Phenomenal addition! Completely transformed my days. Can't go without it. Strongly endorse for all. A game-changer for sure! One of those rare places where the photos do not do justice to the reality – ."
    },
    {
        avatar: "https://img.freepik.com/free-photo/pretty-smiling-joyfully-female-with-fair-hair-dressed-casually-looking-with-satisfaction_176420-15187.jpg",
        name: "Mrs Aliyaha",
        review: "Amazing product! It changed my life. Can't live without it now. Highly recommended to everyone! One of those rare places where the photos do not do justice to the reality – ."
    }]
function FeatureAgents() {

    return (

        <div>
            <div className='plr'>
                <h3 className='text-center' style={{ fontFamily: "fantasy" }}>Featured Agents</h3>
                <div className='row'>
                    {testiomnialData?.map((item, i) => {
                        return (
                            <>
                                <div className='col-lg-4 col-md-6 col-sm-12 parent'>
                                    <div style={{ padding: "20px 0" }}>
                                        <img src={item.avatar} alt='client' />
                                    </div>
                                    <div className="justify-content-between d-flex align-items-center">
                                        <h5 className="m-0">{item.name}</h5>
                                        <div className="">
                                            <span className="likee"><BiSolidLike /></span>
                                            &nbsp;
                                            &nbsp;
                                            <span className="likee"><GrStar /></span>
                                            <span className="likee"><GrStar /></span>
                                            <span className="likee"><GrStar /></span>
                                            <span className="likee"><LiaStarHalfAltSolid /></span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="" style={{ textAlign: "justify" }}>{item.review}</p>
                                    </div>
                                    <div className="review"></div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

        </div>

    )
}

export default FeatureAgents
