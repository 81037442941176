import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import bann1 from "../../../assets/img/banner/bann1.jpg";
import bann2 from "../../../assets/img/banner/bann2.jpg";


import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";

function Banner() {
  const [data, setData] = useState()

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/banner/public`)
      setData(res.data)
    } catch (error) {
      alert('Server Error BannerList')
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <section className="bannerSection">
        <Slider {...settings}>
          <Link to="">
            <div className="bannerItem">
              <div
                className="bannerDetail"
                style={{ backgroundImage: `url(${bann1})` }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="bannerContent">
                        <div className="title">

                          <div className="percentOff">
                            <h1 style={{ color: "transparent" }}>.</h1>
                          </div>
                        </div>

                        <div className="shopNow">
                          <Link to="/products">Contact Us</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link to="">
            <div className="bannerItem">
              <div
                className="bannerDetail"
                style={{ backgroundImage: `url(${bann2})` }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="bannerContent">
                        <div className="title">

                          <div className="percentOff">
                            <h1 style={{ color: "transparent" }}>.</h1>
                          </div>
                        </div>

                        <div className="shopNow">
                          <Link to="/products">Contact Us</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </Slider>
      </section>
    </>
  );
}

export default Banner;
