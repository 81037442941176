/* import productSmall1 from "../../assets/img/products/productSmall1.jpg";
import productSmall2 from "../../assets/img/products/productSmall2.jpg";
import productSmall3 from "../../assets/img/products/productSmall3.jpg";
import productSmall4 from "../../assets/img/products/productSmall4.jpg";


import productLarge1 from "../../assets/img/products/productLarge1.jpg";
import productLarge2 from "../../assets/img/products/productLarge2.jpg";
import productLarge3 from "../../assets/img/products/productLarge3.jpg";
import productLarge4 from "../../assets/img/products/productLarge4.jpg"; */


import pfs1 from "../../assets/img/pforsale/pfs1.jpg";
import pfs2 from "../../assets/img/pforsale/pfs2.jpg";
import pfs3 from "../../assets/img/pforsale/psf9.jpg";
import pfs4 from "../../assets/img/pforsale/pfs4.jpg";
import pfs5 from "../../assets/img/pforsale/pfs5.jpg";
import pfs6 from "../../assets/img/pforsale/pfs6.jpg";
import pfs7 from "../../assets/img/pforsale/pfs7.jpg";
import pfs8 from "../../assets/img/pforsale/pfs8.jpg";
// import pfs9 from "../../assets/img/pforsale/pfs5.jpg";


export const productIData = {
  data: [
    {
      id: 1,
      url: pfs1,
      alt: "productSale1",
    },

    {
      id: 3,
      url: pfs3,
      alt: "productSale3",
    },
    {
      id: 4,
      url: pfs4,
      alt: "productSale4",
    },
    {
      id: 5,
      url: pfs5,
      alt: "productSale5",
    },
    {
      id: 6,
      url: pfs6,
      alt: "productSale6",
    },
    {
      id: 7,
      url: pfs7,
      alt: "productSale7",
    },
    {
      id: 2,
      url: pfs2,
      alt: "productSale2",
    },
    {
      id: 8,
      url: pfs8,
      alt: "productSale8",
    },
  ]
};
